<template>
    <v-card>
        <v-card-title>
            <h3>Clients</h3>
            <v-spacer />
            <v-text-field
                v-model="keywords"
                append-icon="mdi-magnify"
                label="Search Clients..."
                clearable
                single-line
                hide-details
            />
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        v-on="on"
                        color="primary"
                        @click="newClientForm()"
                        fab
                        dark
                    >
                        <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span>New Client</span>
            </v-tooltip>
        </v-card-title>
        <v-card-text>
            <clients-grid
                :keywords.sync="keywords"
                :reload.sync="reloadVar"
                @reload="reloadGrid"
                @edit-client="editClient"
            />
        </v-card-text>
        <v-dialog v-model="clientFormDialog" persistent max-width="600">
            <client-form
                :client.sync="formClient"
                @client-form-saved="reloadGrid()"
                @client-form-closed="closeClientForm()"
            />
        </v-dialog>
    </v-card>
</template>
<script>
import ClientsGrid from '@/views/clients/ClientsGrid'
import ClientForm from '@/views/clients/ClientForm'
export default {
    name: 'home',
    components: {
        'clients-grid': ClientsGrid,
        'client-form': ClientForm,
    },
    data: () => ({
        keywords: '',
        reloadVar: 1,
        clientFormDialog: false,
        formClient: {},
    }),
    methods: {
        reloadGrid: function() {
            this.reloadVar++
            this.closeClientForm()
        },
        newClientForm: function() {
            this.formClient = {}
            this.clientFormDialog = true
        },
        closeClientForm: function() {
            this.clientFormDialog = false
        },
        editClient: function(client) {
            this.formClient = client
            this.clientFormDialog = true
        },
    },
}
</script>
