<template>
    <v-container fluid>
        <ApolloQuery
            :query="require('@/graphql/client/allClients.gql')"
            :variables="{ garbage: reload }"
        >
            <template v-slot="{ result: { loading, error, data } }">
                <v-progress-linear indeterminate v-if="loading" />
                <v-data-iterator
                    v-if="data && !loading"
                    :items="data.allClients"
                    :search="keywords"
                    :items-per-page.sync="itemsPerPage"
                    :footer-props="{ itemsPerPageOptions }"
                >
                    <template v-slot:default="props">
                        <v-row>
                            <v-col
                                v-for="item in props.items"
                                :key="item.id"
                                cols="12"
                                sm="6"
                                md="4"
                                lg="3"
                            >
                                <client-card
                                    :client.sync="item"
                                    @delete-clicked="promptForDelete"
                                    @edit-clicked="editClient"
                                />
                            </v-col>
                        </v-row>
                    </template>
                </v-data-iterator>
                <v-alert type="error" v-else-if="error">
                    There was an error loading data. The server said...<br />
                    <strong>{{ error }}</strong>
                </v-alert>
            </template>
        </ApolloQuery>
        <v-dialog
            v-model="deleteDialog"
            persistent
            max-width="320"
            v-if="selectedClient.name != ''"
        >
            <v-card>
                <v-card-title class="headline">Delete Client?</v-card-title>
                <v-card-text>
                    <p>
                        You are about to delete the client
                        <strong>{{ selectedClient.name }}</strong
                        >. This will remove the client record as well as any
                        associations with users and projects. This cannot be
                        undone. Are you sure you want to delete this client?
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        :loading="deleteLoading"
                        :disabled="deleteLoading"
                        text
                        @click="deleteDialog = false"
                        >{{ $t('cancel') }}</v-btn
                    >
                    <v-btn
                        color="primary darken-1"
                        :loading="deleteLoading"
                        :disabled="deleteLoading"
                        text
                        @click="deleteClient"
                        >{{ $t('delete') }}</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import ClientCard from '@/views/clients/ClientCard'
export default {
    name: 'ClientsGrid',
    components: {
        'client-card': ClientCard,
    },
    props: {
        keywords: {
            type: String,
            required: true,
        },
        reload: {
            type: Number,
            required: true,
        },
    },
    data: () => ({
        itemsPerPageOptions: [4, 8, 12],
        itemsPerPage: 8,
        deleteDialog: false,
        selectedClient: {},
        deleteLoading: false,
    }),
    methods: {
        promptForDelete(client) {
            this.selectedClient = client
            this.deleteDialog = true
        },
        deleteClient() {
            this.deleteLoading = true
            this.$apollo
                .mutate({
                    // Query
                    mutation: require('@/graphql/client/deleteClient.gql'),
                    // Parameters
                    variables: {
                        id: this.selectedClient.id,
                    },
                })
                .then(data => {
                    // report success
                    this.$emit('client-form-closed', data)
                    this.$emit('reload')
                    this.deleteDialog = false
                    this.deleteLoading = false
                })
                // eslint-disable-next-line no-unused-vars
                .catch(error => {
                    // Error
                    this.$emit('reload')
                    this.deleteDialog = false
                    this.deleteLoading = false
                })
        },
        editClient(client) {
            this.$emit('edit-client', client)
        },
    },
}
</script>
